@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body{
  background: white;
}

h1, h4, p, a{
  color: white;
  text-decoration: none;
}

h1 {
  color: white;
  text-decoration: none;
}
ul{
  list-style: none;
}

.btn {
  padding:  12px 32px;
  font-size:  1rem;
  text-transform: uppercase;
  background: white;
  color: black;
  border: 1px solid #ffff;
  font-weight:  600;
  cursor: pointer;
}

.btn2 {
  padding:  12px 32px;
  font-size:  1rem;
  text-transform: uppercase;
  background: black;
  color:white;
  border: 1px solid #ffff;
  font-weight:  600;
  cursor: pointer;
}




.btn-light {
  background: transparent;
  color: white;
  animation: blink 2.5s linear infinite;
}

@keyframes blink {
  100% {
    opacity: 50;
  }
}

.btn:hover{
  background: rgba(0, 0, 0);
  color: white;
  transition: 0.1s;
}


.btn2:hover{
  background: rgba(255, 255, 255);
  color: black;
  transition: 0.1s;
}
@media (max-width: 992px) {
  .btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .btn {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}



/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
