.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: white;
  text-align: center;
  }
  
  .left1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    max-width: 70%;
    margin: 0 auto;
  }
  
  #abt_h1 {
  color: black;
  font-size: 4vw;
  margin-bottom: 2vw;
  }
  
  #p_tag2 {
  
  list-style: none;
  margin: auto;
  padding: 0;
  width: 75%;
  font-size: 20px;
  line-height: 1.5;
  text-align: left; 
  }
  
  .li_1 {
    color: #808080;
  list-style: disc;
  margin-bottom: 1vw;
  }
  
  .btn {
  padding: 1vw 2vw;
  background-color:  black;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2vw;
  margin-top: 2vw;
  cursor: pointer;
  transition: all 0.3s ease;
  }
  
  .btn:hover {
  background-color: #0069d9;
  }
  
  .image {
  width: 100%;
  max-width: 100%;
  height: auto;
  }
  
  @media (max-width: 500px) {
    .left1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90vw;
      max-width: 100%;
      margin: 0 auto;
  }
  #abt_h1 {
  font-size: 8vw;
  }
  #p_tag2 {
  font-size: 4vw;
  }
  .btn {
  padding: 0.5vw 1vw;
  font-size: 1vw;
  }

  .image {
    width: 50%;
    max-width: 50%;
    height: auto;
    }
}
  
  #heading-2 {
  color: white;
  text-align: center;
  position: absolute;
  
  top: 26vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  }
  
  #h1_2Tag {
    margin-top: 15px;
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 2vw;
  color: black;
  }
  
  #about_tag {
    
    margin: auto;
    color: grey;
    font-size: 2vw;
    margin-top: 1vw;
    text-align: center;
    width: 54%;
  }
  
  @media (max-width: 992px) {
  #h1_2Tag {
  font-size: 10vw;
  }
  #about_tag {
    width: 100%;
  font-size: 4vw;
  }
  .btn {
    padding: 4vw 12vw;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 25px;
    margin-top: 2vw;
    cursor: pointer;
    transition: all 0.3s ease;
    }
  }