.header-2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #000;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
}

.header-bg-2 {
    background: #fff;
}

.logout {
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    font-size: 1.2rem;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.logout-hover {
    color: #000;
    transition: all 0.3s ease-in-out;
}

.logout:hover .logout-hover {
    color: red;
}