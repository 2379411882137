.footer {
  
    background-color: #333;
    color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .footer-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  
  .left,
  .right {
    margin: auto;
    width: calc(100% - 1rem);
  }
  
  .location,
  .phone,
  .email {
    display: flex;
    align-items: left;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
    }
  
    .left,
    .right {
      width: 100%;
    }
  }
  

a {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

a:hover {
    transform: scale(1.1);
}

.social .fa-linkedin {
    color: #0077B5;
    border: 2px solid #0077B5;
    padding: 10px;
    border-radius: 50%;
}

.social .fa-linkedin:hover {
    background-color: #0077B5;
    color: #fff;
}


.footer {
    /* position: fixed; */
  margin-top: 164px;
    width: 100%;
    
  
    /* background: linear-gradient(to bottom, #1a1a1a, #141414); */
    /* background-image: linear-gradient(to bottom, #000000, #232223, #413f40, #625f5f, #858080); */
    
    background: hsla(0, 0%, 22%, 1);

background: linear-gradient(90deg, hsla(0, 0%, 22%, 1) 100%, hsla(286, 43%, 46%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(0, 0%, 22%, 1) 100%, hsla(286, 43%, 46%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(0, 0%, 22%, 1) 100%, hsla(286, 43%, 46%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#383838", endColorstr="#9243A9", GradientType=1 );

    
    
    
    
    background-size: cover;
    
  
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

}
    
    .footer-container {
    max-width: 1140px;
     margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    }
    
    h4 {
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    }
    
    p {
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    }
    
    .location {
    display: flex;
    margin-bottom: 0.8rem;
    }
    
    .location p {
    line-height: 30px;
    }


    .footer-container h4:hover, .footer-container p:hover {
        color: #fff;
        transition: color 0.3s ease-in-out;
        }
        
        @media screen and (max-width : 640px) {
        .footer-container {
        grid-template-columns: 1fr;
        }
        }

        .footer-container h4:hover, .footer-container p:hover {
            /* animation: blink 1s linear infinite; */
            color: #fff;
            transition: color 0.3s ease-in-out;
        }
        
        @keyframes blink {
          50% {
            opacity: 0;
          }
        }
        
    


    
    
    








