
   
   .section-center {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   margin-bottom: -49px;
   }
   
   .slide {
   position: relative;
   width: 30%;
   transition: all 0.5s ease-in-out;
   }
   
   .lastSlide {
   position: absolute;
   left: -100%;
   }
   
   .activeSlide {
   z-index: 2;
   left: 0;
   }
   
   .nextSLide {
   position: absolute;
   left: 100%;
   }
   
   #nameH4{
      color : black;
               font-size: 1.5rem;
         margin-top: 1.5rem;
         text-align: center;
     
   }
   .person-img {
  
   padding: 0px;
   color:#686262;
   border-style: solid;
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 738px;
   
 



   
   }
   
   h4 {
   font-size: 1.5rem;
   margin-top: 1.5rem;
   text-align: center;
   }
   
   .title1 {
      margin-bottom: 13px;
      font-size: 26px;
      font-weight: 300;
      color: #423f3f;
      text-align: center;
   }
   
   .text1 {
      color: black;
      font-size: 25px;
      font-weight: 300;
      margin-top: 1.5rem;
      text-align: center;
      margin: auto;
      width: 100%;
  }
   
   @media only screen and (max-width: 768px) {
      /* Add your CSS rules for devices with a screen width of less than 768px here */
      /* For example, you can reduce the font size and adjust the layout */
      body {
        font-size: 14px;
      }
      #header {
        width: 100%;
        float: none;
      }
    }

   /* Add responsive styles */
   @media (max-width: 991px) {

      .person-img {
         width: 50%;
         height: 50%;
         object-fit: cover;
      
         
         border-radius: 715px;
      }
   .section-center {
   display: flex;
   justify-content: center;
   }
   
   .slide {
   width: 75%;
   }
   }
   
   @media (max-width: 767px) {
   .slide {
   width: 100%;
   }
   }


   @media (max-width: 991px) {
      .section-center {
      display: flex;
      justify-content: center;
      align-items: center;
      }
      
      .slide {
      width: 80%;
      text-align: center;
      }
      
      h4, .title, .text {
      text-align: center;
      }
      }
      
      @media (max-width: 767px) {
      .section-center {
      display: flex;
      justify-content: center;
      align-items: center;
      }
      
      .slide {
      width: 100%;
      text-align: center;
      }
      
      h4, .title, .text {
      text-align: center;
      }
      }

      
      .section {
         padding: 3em 0;
         }