.technoid3 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

#h1_2Tag{

    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #333;

}

.mask2 {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
}

.heading {
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fadeIn {
    animation: fadeIn 1s ease-in-out;
}

.fadeOut {
    animation: fadeOut 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

#consult {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#about_tag {
    font-size: 1.2rem;
    margin-top: 1rem;
}

@media only screen and (max-width: 767px) {
    .heading {
        font-size: 1.2rem;
    }

    #consult {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}

#h1_2Tag {
    font-size: 3vw;
    font-weight: bold;
    margin-bottom: 2vw;
    color: #333;
    text-align: center;
  }
  
  #about_tag {
    color: grey;
    font-size: 1.2vw;
    margin-top: 1vw;
    text-align: center;
  }
  @media (max-width: 992px) {
    #h1_2Tag {
      font-size: 2vw;
    }
    #about_tag {
      font-size: 1vw;
    }
  }
  
